import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';


@Injectable({
  providedIn: 'root'
})


export class CecosService implements OnInit{

   Url: any [] = this.auth.urlApi();

  constructor(private http: HttpClient, private auth: AuthService) {
    //console.log('Servicio Cecos activo');
   }

   ngOnInit() {
   };

   getCecosPorCliente(){
    let Usuario = this.auth.getUsuarioLogeado();
     return  this.http.get(`${this.Url}/cecos/${Usuario.CodCliente}`);
   };
   
   getRutAFacturarCliente(){
    let Usuario = this.auth.getUsuarioLogeado();
     return this.http.get(`${this.Url}/rutAFacturar?pCodCliente=${Usuario.CodCliente}&pIdUsuario=${Usuario.Id}`)
   };

   getNivelTarjetaCliente(){
    let Usuario = this.auth.getUsuarioLogeado();
     return this.http.get(`${this.Url}/nivelTarjeta/${Usuario.CodCliente}`)
   };

   rutFacturarUsuario(IdUsuario,CodCliente){
     return this.http.get(`${this.Url}/rutFacturarUsuario?pCodCliente=${CodCliente}&pIdUsuario=${IdUsuario}`)
   };

   bloqueaRutFacturarUsuario(IdUsuario,CodCliente){
    return this.http.get(`${this.Url}/bloqueaRutFacturarUsuario?pCodCliente=${CodCliente}&pIdUsuario=${IdUsuario}`)
  };

  addRutFacturarUsuario(IdUsuario,CodCliente,idRutFacturar){
    let Usuario = this.auth.getUsuarioLogeado();
    return this.http.get(`${this.Url}/addRutFacturarUsuario?pCodCliente=${CodCliente}&pIdUsuario=${IdUsuario}&pIdRutFacturar=${idRutFacturar}&pIdUsuarioCambio=${Usuario.Id}`)
  };
}
