import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MisSolicitudesService {
  Url: any [] = this.auth.urlApi();
  constructor(private http: HttpClient, private auth: AuthService) {
    //console.log('Servicio mis tarjetas activo');
   }

   getSolicitudesPorCliente(){ 
    let Usuario = this.auth.getUsuarioLogeado();
     return  this.http.get(`${this.Url}/misSolicitudes?pCodCliente=${Usuario.CodCliente}&pIdUsuario=${Usuario.Id}`);
   };

   getSolicitudesPorClienteExport(){ 
    let Usuario = this.auth.getUsuarioLogeado();
     return  this.http.get(`${this.Url}/misSolicitudesExport?pCodCliente=${Usuario.CodCliente}&pIdUsuario=${Usuario.Id}`);
   };

   
   getCountSolicitudesPendientes(){ 
    let Usuario = this.auth.getUsuarioLogeado();
     return  this.http.get(`${this.Url}/CountSolicitudesActivas/${Usuario.CodCliente}`);
   };

   getSolicitudesPorClienteDetalle(argumento){ 
     return  this.http.get(`${this.Url}/misSolicitudesDetalle/${argumento}`);
   };

   insSolicitudWebUnitaria(CentroCosto, rutFacturar, MontoTarjeta, RutUsuario, NombreUsuario, Comentario, CodCliente, UsuarioPeticion){ 
    return  this.http.get(`${this.Url}/insSolicitudWebUnitaria?CentroCosto=${CentroCosto}
    &rutFacturar=${rutFacturar}
    &MontoTarjeta=${MontoTarjeta}
    &RutUsuario=${RutUsuario}
    &NombreUsuario=${NombreUsuario}
    &Comentario=${Comentario}
    &CodCliente=${CodCliente}
    &UsuarioPeticion=${UsuarioPeticion}`);
  };

  insSolicitudWebMasiva(CentroCosto, rutFacturar, MontoTarjeta, Comentario, CodCliente, UsuarioPeticion){ 
    return  this.http.get(`${this.Url}/insSolicitudWebMasiva?pCentroCosto=${CentroCosto}
    &pRutFacturar=${rutFacturar}
    &pMontoTarjeta=${MontoTarjeta}
    &pComentario=${Comentario}
    &pCodCliente=${CodCliente}
    &pUsuarioPeticion=${UsuarioPeticion}`);
  };

  insSolicitudWebMasivaDetalle(CodCliente, MontoTarjeta, CentroCosto, idSolicitudtarjeta, rutUsuario, nombreUsuario, rutFacturar){ 
    return  this.http.get(`${this.Url}/insSolicitudWebMasivaDetalle?pCodCliente=${CodCliente}
    &pMontoTarjeta=${MontoTarjeta}
    &pCentroCosto=${CentroCosto}
    &PIdSolicitudTarjeta=${idSolicitudtarjeta}
    &pRutUsuario=${rutUsuario}
    &pNombreUsuario=${nombreUsuario}
    &pRutFacturar=${rutFacturar}`);
  };

  NotificacionMailSolicitudTarjeta(TipoSolicitud, Comentario, Subject, nroTicket,cc, estado,nroTarjeta,Direccion, tipoEnvio){
    let Usuario = this.auth.getUsuarioLogeado();
    return  this.http.get(`${this.Url}/envioMailNotificacionSolicitud?pNombreUsuario=${Usuario.Nombre}
    &pMailUsuario=${Usuario.Correo}
    &pSubject=${Subject}
    &pCC=${cc}
    &pTipoSolicitud=${TipoSolicitud}
    &pComentario=${Comentario}
    &pNroTicket=${nroTicket}
    &pEstado=${estado}
    &pCliente=${Usuario.razonsocial}
    &pNroTarjeta=${nroTarjeta}
    &pDireccion=${Direccion}
    &ptipoEnvio=${tipoEnvio}`);
  };
};
